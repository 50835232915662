<template>
  <div
    class="
      d-flex
      justify-content-center
      align-items-center
      flex-wrap flex-sm-nowrap
      bg-white
      px-16
      pb-16
      rounded-8
    "
    :class="{ 'is-active': schedule.inPeriod }"
  >
    <div class="flex-grow-1 d-flex align-items-center me-16">
      <div class="time text-center p-16 rounded-8 mt-16 me-16">
        <span class="fs-20 fw-bold">{{ schedule.time_start }}</span
        ><br />
        <span class="fs-11">s.d. {{ schedule.time_end }}</span>
      </div>

      <div class="flex-grow-1 me-16 mt-16">
        <strong class="fs-20">{{ schedule.subject.name }}</strong
        ><br />
        <small class="text-muted">{{ schedule.teacher.user.name }}</small>
      </div>
    </div>

    <div class="btn-group mt-16">
      <router-link
        class="btn btn-sm d-inline-flex align-items-center"
        :class="
          schedule.inPeriod ? 'btn-outline-primary' : 'btn-outline-secondary'
        "
        :to="schedule.attendanceRoutePath"
      >
        <ListIcon class="me-8" />
        Presensi
      </router-link>
      <router-link
        class="btn btn-sm d-inline-flex align-items-center"
        :class="
          schedule.inPeriod ? 'btn-outline-primary' : 'btn-outline-secondary'
        "
        :to="schedule.materialsRoutePath"
      >
        <BookIcon class="me-8" />
        Materi
      </router-link>
    </div>
  </div>
</template>

<script>
import BookIcon from 'bootstrap-icons/icons/book.svg';
import ListIcon from 'bootstrap-icons/icons/list.svg';

export default {
  components: { BookIcon, ListIcon },

  props: ['schedule'],
};
</script>

<style lang="scss" scoped>
.time {
  background: $gray-200;

  .is-active & {
    background: $primary;
    color: $white;
  }
}
</style>
